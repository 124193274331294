import React from 'react';
import LayoutSecondary from '../components/layout/LayoutSecondary';

import appConfigAppImage from '../assets/images/punchin-configuration.jpeg'

const Success = () => (
  <LayoutSecondary>
    <section className="pt-20 md:py-15">
      <div className="container mx-auto px-8">
        <h1 className="blocktext-4xl lg:text-3xl xl:text-2xl font-bold leading-none w-full">
          Mobile application documentation
        </h1>
        <div className="text-xl py-5 md:py-20">
          <p className="my-8 font-light">This section will explain how to use <strong>Punch-in</strong> mobile application.
            If you are here, most likely you have already downloaded Punch-in Android application from here or from Google Play store.
            If not, please go ahead and download it on all devices you intend to use for face recognition.
          </p>
          <h2 className="blocktext-xl lg:text-2xl xl:text-xl font-bold leading-none w-full">First-time execution</h2>
          <p className="my-8 font-light">
            When launching Punch-in Android application, you will be prompted to enter your credentials:
          </p>
          <div className="m-auto">
            <img src={appConfigAppImage} alt="back-end app configuration" />
          </div>
          <p className="my-8 font-light">
            This information will be contained in the e-mail you received right after your payment has been successfully processed.
            You can use the QR code for a quicker configuration (button <strong>Scan QR code</strong> or <strong>Link to Scan</strong>
             depending on the version of your application). If you need to change any of the configuration settings,
             make sure that the <strong>Edit configuration</strong> radio button is on.
          </p>
          <p className="my-8 font-light">
            You can now start the face recognition process. From the main menu, click <strong>Start application</strong>
          </p>
          <p className="my-8 font-light">
            The application will sync up with the server, i.e. it will import all your employees images, after which you can start using it for face recognition.
          </p>
          <p className="my-8 font-light">
            <strong>Tips for optimal facial recognition</strong>: when you are in front of the application,
              try to keep still for a few seconds, looking straight at the camera, possibly with a passport picture expression, which should be the same as the
               picture that was used for your enrollment.
          </p>

        </div>
      </div>
    </section>
  </LayoutSecondary>
);

export default Success;
